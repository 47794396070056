import React, { Fragment } from 'react';
import env from '../../env';
import { SiteLink } from '../../components';
import { usePost } from '../../components';
import { ErrorPopup, Loading } from '../../components';
import { useRouter } from '../../components';
import { ProductTile } from '../ecm/productList/productTile';

export function SearchResult(props) {
  const { query } = useRouter();
  const post = usePost();
  const keyword = query.q;
  //console.log(keyword);

  React.useEffect(() => {
    post.send(env.apiBase + '/api/search/search', { keyword: keyword });
    // eslint-disable-next-line
  }, [keyword]);

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }
  
  const data = post.response;

  const pages = data.pages;
  const products = data.products;

  if (pages.length === 0 && products.length === 0) return (
    <div className="alert alert-danger" role="alert">
      No result found.
    </div>
  )
  return (
    <Fragment>
      {products.length > 0 && <div style={{maxWidth:'960px',margin:'auto'}}>
        <h3>Products</h3>
        <ProductList products={products} />
      </div>}
      {pages.length > 0 && <div style={{maxWidth:'960px',margin:'auto'}}>
        <h3 style={{ marginTop: '2rem' }}>Pages</h3>
        <PageList pages={pages} />
      </div>}
    </Fragment>
  );
}

function ProductList({ products }) {
  return (
    <div className="search-result productList">
      {products.map((product, index) =>
        <ProductTile product={product} key={product.productCode}></ProductTile>
      )}
    </div>
  );
}

function PageList({ pages }) {
  return (
    <div className="pageList row">
      {pages.map((page, index) =>
        <div className="pageListItem col-md-6 col-lg-4" key={index}>
          <div className="pageListItem__inner">
            <SiteLink className="pageListItem__title" to={page.pageUrl}>{page.pageTitle}</SiteLink>
            <div className="pageListItem__highlight" dangerouslySetInnerHTML={{ __html: page.searchHighlight }}></div>
          </div>
        </div>
      )}
    </div>
  );
}