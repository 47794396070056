import React, { useEffect } from 'react';
import { useRouter } from '../../../components';
import { useGet } from '../../../components';
import { Item } from '../../items/item';
import env from '../../../env';
import { loadJs } from '../../../lib/net-loader';
import { CmsPageController } from '../../services/cms-page-controller';
import { ErrorPopup, Loading } from '../../../components';
import cmsUtils from '../../utils/cmsUtils';

// function useQuery() {
//   return new URLSearchParams(useLocation().search);
// }

export function EditPage(props) {
  //const [page, setPage] = useState(null);

  //const pageId = props.pageId;
  const { query } = useRouter();
  const { cmsViewMode = 'edit', id, publishVer, editVer } = query;
  const pageId = id;

  //console.log(pageId, cmsViewMode, env.apiBase, env)

  const get = useGet();
  useEffect(() => {
    get.send(env.apiBase + '/api/cms/GetPage/' + pageId + '?cmsViewMode=' + cmsViewMode
      + (publishVer ? '&publishVer=' + publishVer : '')
      + (editVer ? '&editVer=' + editVer : '')
    );
    // eslint-disable-next-line
  }, [pageId]);

  if (get.loading()) {
    return <Loading />
  } else if (get.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={get.errors} />
  }

  //console.log(router.pathname);
  //console.log(res);

  const pageData = get.response.data;
  const cmsOption = { isCmsEdit: true, cmsViewMode: cmsViewMode };
  cmsOption.pageData = pageData;

  const cmsPageController = new CmsPageController();
  
  if (pageData){
    let themeColor = cmsUtils.payload(pageData, 'ColorTheme', 'magenta');
    let _hideBlogTitle = cmsUtils.payload(pageData, 'HideBlogTitle', 'false');
    let _class = document.querySelector('div.body-content').className.replace(/theme-color-.*/g, '');
    document.querySelector('#root div.body-content').className= _class + "theme-color-" + themeColor;

    document.querySelector('div.body-content').classList.remove("hide-blog-title-true");
    if(_hideBlogTitle === 'true'){
      document.querySelector('div.body-content').classList.add("hide-blog-title-true");
    }
    
  }

  if (cmsViewMode !== 'viewonly') {
    const ver = 1;
    loadJs(
      env.resourceBase + '/dist/jquery-3.4.1/jquery.min.js?v=' + ver
    ).then(data => {
      return loadJs(
        env.resourceBase + '/dist/cms/ckeditor/ckeditor.js?v=' + ver
      )
    }).then(data => {
      // console.log('script loaded ', data);
      cmsPageController.init({});
      document.querySelector('body').classList.add('cmsedit');
    }).catch(error => console.log(error));
  } else {
    const ver = 1;
    loadJs(
      env.resourceBase + '/dist/jquery-3.4.1/jquery.min.js?v=' + ver
    ).then(data => {
      cmsOption.isCmsEdit = false;
      cmsPageController.init({ viewOnly: true });
    }).catch(error => console.log(error));
  }

  //setPage(res.response.data);
  const sectionItems = pageData.sections.map((section, index) =>
    <Section item={section} key={section.sectionName + index} cmsOption={cmsOption}></Section>
  );
  return (
    <div className={`cmsPage-edit-mode cmsPage cmsPage--${pageData.pageTypeCode}`}>
      {sectionItems}
    </div>
  );
}


function Section(props) {
  const item = props.item;
  //const sectionName = item.sectionName;
  const subItems = item.items || [];
  const cmsOption = props.cmsOption || {};

  if (!subItems || subItems.length === 0)
    return (<></>);

  const subItemsElts = subItems.map((subItem, index) =>
    <Item item={subItem} key={subItem.itemId} cmsOption={cmsOption}></Item>
  );
  return (
    <>
      {subItemsElts}
    </>
  );
}

