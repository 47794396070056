/** @jsx jsx */
import { jsx } from '@emotion/core';
import React from 'react';
import cmsUtils from '../../utils/cmsUtils';
import utils from '../../../utils';
import { SiteLink } from '../../../components';
//import facepaint from 'facepaint';

export function BannerItem(props) {
  const item = props.item;
  const cmsOption = props.cmsOption || {};

  const bgUrl = utils.site.resourcePath(cmsUtils.payload(item, 'ImageUrl'));
  //const bgUrl = 'https://scunci.stg5.jaba.com.au/sites/scunci/media/images/circle-model-01.png';
  //const bgUrl = 'https://scunci.stg5.jaba.com.au/sites/scunci/media/images/circle-model-03.png';
  
  const captionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'CaptionTitle');
  const extraCaptionTitle = cmsUtils.payload(item, 'CaptionTitle2') || cmsUtils.payload(item, 'ExtraCaption');
  const captionButton = cmsUtils.payload(item, 'CaptionButton') && cmsUtils.payload(item, 'CaptionButton') !== "" ? cmsUtils.payload(item, 'CaptionButton') : "READ MORE";
  const linkUrl = cmsUtils.payload(item, 'LinkUrl2') || cmsUtils.payload(item, 'LinkUrl');
  
  const useLayer = cmsUtils.payload(item, 'UseLayer') || 'off';
  const bigLayer = cmsUtils.payload(item, 'BigLayer') || 'magenta';
  //const smallLayer = cmsUtils.payload(item, 'SmallLayer') || 'magenta';
  
  const titlePanel = captionTitle ? (
    linkUrl ? (<div>
      <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
      <SiteLink to={linkUrl} className="banner-item__link"> { captionButton } </SiteLink>
    </div>
    ) : (<div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>)
  ) : null;

  // const linkOverlay = linkUrl && !captionTitle ? (
  //   <SiteLink className="bannerItem__overlay" to={linkUrl}></SiteLink>
  // ) : null;

  const cssClass = utils.classNames('cms_item', 'banner-item', 'bannerSize', item.cssClass || item.anchorName || '', (titlePanel && useLayer === 'off') && 'bannerHasTitlePanel');
  
  // const mixin = {
  //   rem: (px, baseSize = 40) => `calc(${px / baseSize} * var(--useLayerOption))`
  // }
  // const breakpoints = [768, 1200, 1920, 2450];
  // const mq = facepaint(
  //   breakpoints.map(bp => `@media (min-width: ${bp}px)`)
  // )
  // const layerStyle = {
  //   bigLayer : mq({
  //     width: [mixin.rem(1600), null, null, null, '1760px'],
  //     height: [mixin.rem(1600), null, null, null, '1760px']
  //   }),
  //   middleLayer : mq({
  //     width: [mixin.rem(900), null, null, null, '1180px'],
  //     height: [mixin.rem(900), null, null, null, '1180px']
  //   }),
  //   smallLayer : mq({
  //     width: [mixin.rem(100), null, null, null, '350px'],
  //     height: [mixin.rem(100), null, null, null, '350px']
  //   })
  // }

  const bannerItemStyle = {
    '&::after' : {
      backgroundImage: utils.css.bgUrlStyle(bgUrl)
    }
  };

  return (
    <div className='banner-item-container'>
      
      <SiteLink to={linkUrl}>

      <div className={cssClass} data-cms-item-id={cmsOption.isCmsEdit && item.itemId} id={item.anchorName}></div>
      
      <div className={`banner-layer ${(captionTitle && captionTitle !== '') ? 'with__title' : 'no__title'}`}>
        <div css={bannerItemStyle} className={`banner-item-dot`}></div>
      </div>
      
      <div className='text-layer'>
        <div className="banner-item__title" dangerouslySetInnerHTML={{ __html: captionTitle }}></div>
        <div className="banner-item__extra__caption" dangerouslySetInnerHTML={{ __html: extraCaptionTitle }}></div> 
        {
          (captionTitle && captionTitle !== '') &&
          <div className="banner-item__button"> {captionButton} </div>
        }
      </div>
      {
        (useLayer && useLayer === 'on') &&
        
        <React.Fragment>
          <div className='big-layer'>
            <div className={`big-dot layer-item ${bigLayer}`}></div>
          </div>
          {/* <div className='small-layer'>
            <div className={`small-dot layer-item ${smallLayer}`}></div>
          </div> */}
        </React.Fragment>
      }
    </SiteLink>
    </div>
  );

}