//import { css } from '@emotion/core';
import variables from '../../../cssInJs/variables';
import { mq } from '../../../cssInJs';
export default {
  desc: {
    fontSize: '0.9rem',
    'p': {
      //fontSize: '1rem !important',
      fontFamily: variables.familyBase + ' !important',
      marginBottom: '0.5rem !important',
      lineHeight: '1.3 !important'
    }
  },
  heroImageContainer : mq({
    //textAlign: 'center',
    height: ['180px', '340px'],
    position: 'relative',
  }),
  heroImage : mq({
    //maxWidth: '350px !important',
    //height: '100% !important'
    maxHeight: ['180px', '340px'],
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
  }),
  productPageCol : mq({
    paddingLeft: '45px',
    paddingRight: '45px'
  }),
  productFavorite : {
    cursor: 'pointer',
    '&.checked' : {
      color: 'red'
    }
  },
  productBreadCrumbs : mq({
    padding: '0px',
    listStyle: 'none',
    marginBottom:'0.5rem',
    display: 'block',
    'li' : mq({
      display:'inline-block',
      marginRight:'1.5rem',
      position: 'relative',
      fontSize:'0.7rem',
      '&:not(:last-child)::after, &.breadCrumb.last-item::after' : {
        content: '">"',
        position: 'absolute',
        right: '-1rem'
      },
      '&.breadCrumb::after' : {
        content: '"|"',
        position: 'absolute',
        right: '-1rem'
      }
    })
  }),
  productViewImageBg : mq({
    backgroundPosition: 'center',
    backgroundSize: 'contain',
    paddingTop: ['80px', '120px'],
    backgroundRepeat: 'no-repeat',
    marginLeft: '5px',
    marginRight: '5px',
    '&.current' : {
      border: '1px solid'
    }
  }),
  productViewImages : mq({
    marginTop: ['1rem', '2rem'],
    paddingBottom: ['1rem', '2rem'],
    marginLeft: '-5px',
    marginRight: '-5px',
    display:'flex',
    flexDirection: 'row',
    flexWrap: 'wrap'
  }),

  productViewImage : mq({
    //paddingLeft: '5px',
    //paddingRight: '5px'
    outline: 'none',
    cursor: 'pointer',
    flex: '0 0 25%',
    maxWidth:'25%'
  }),

  productViewShortDes : mq({
    fontSize:['1rem'],
    textAlign: 'center',
  }),

  productViewTitle : mq({
    fontSize:['2.55rem'],
    //textAlign: 'center',
    marginTop:'0px',
    marginBottom:'1rem',
    lineHeight:'1.1',
    color: variables.fontColor,
    'strong' : {
      color: variables.fontColor
    }
  }),

  productViewPrice : mq({
    fontWeight: 700,
    fontSize:'0.8rem'
  }),

  productViewCode : mq({
    fontWeight: 400,
    fontSize:'0.8rem'
  }),

  addToCartBtn : {
    //width: '100%'
    'button, .btn' : {
      width: '100%'
      //transform: 'skewX(-12deg)'
    },
    // 'button.gotocartbtn' : {
    //   marginTop: '1rem'
    // }
  },
  priceText : {
    fontSize: '0.8rem'
  },
  socialMedia : {
    marginTop:'0.5rem',
    '.social-icon-text' : {
      fontSize: '0.8rem'
    }
  },
  attributesRow : {
    cursor: 'pointer',
    //marginTop: '0.5rem',
    //marginBottom: '0.5rem',
    paddingTop: '0.2rem',
    paddingBottom: '0.2rem',
    '&:hover' : {
      backgroundColor: '#999999',
      color: 'white'
    },
    '.svgContainer svg' : {
      transition: 'all 0.5s'
    },
    '.svgContainer.open svg' : {
      transform: 'rotate(180deg)'
    }
  },

  attrContainer : {
    maxHeight: '0px',
    transition: 'all 0.3s',
    overflow: 'hidden',
    '&.open' : {
      maxHeight: '1000px'
    }
  },

  desktopMobile : {
    '&.mobile' : mq({
      display: ['block', 'none']
    }),
    '&.desk__top' : mq({
      display: ['none', 'block']
    })
  },
  quoteContainer : {
    marginBottom: '1rem',
    '.quote__text' : {
      fontSize: '0.8rem',
      marginBottom: '1rem'
    },
    button : {
      //transform: 'skewX(-12deg)'
    }
  },
  enquiryFormContainer : {
      backgroundColor:'rgba(0,0,0,0.3)',
      position: 'fixed',
      top:'0px',
      left:'0px',
      width:'100%',
      height: '100%',
      zIndex:'9999',
      'form.contactForm' : {
        position: 'absolute',
        width: '100%',
        maxWidth: '900px',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: 'grey',
        //paddingTop: '2rem',
        paddingLeft: '1rem',
        paddingRight: '1rem',
        margin: '0px',
        '.closeButton' : {
          textAlign: 'right',
          //margin: '1rem 0px',
          cursor: 'pointer',
          color: 'white',
          marginTop: '0.5rem'
        },
        '.enquireNowText' : {
          marginBottom: '0.5rem',
          color: 'white'
        }
      }
  },
  embedYoutubeContainer : mq({
    position:'relative',
    display:'block',
    height:0,
    padding:0,
    overflow: 'hidden',
    paddingBottom:'56.25%',
    marginBottom:['1rem', null, '0rem'],
    '&.youtube__portrait__item' : mq({
      flex : ['1 0 100%',null,'1 0 50%'],
      maxWidth: ['100%',null,'50%'],
      paddingBottom: ['176%',null,'87%'],
      position : 'relative',
      '.dumyborder__right' : mq({
        position : 'absolute',
        content: '""',
        width : ['5px',null,'5px'],
        height : '100%',
        backgroundColor: 'white',
        zIndex : '9',
        right: '2px',
        display : ['block',null,'block']
      }),
      '.dumyborder__top' : mq({
        position : 'absolute',
        content: '""',
        width : '100%',
        height : ['5px',null,'5px'],
        backgroundColor: 'white',
        zIndex : '9',
        top : '2px',
        display : ['block',null,'block']
      }),
      '.dumyborder__bottom' : mq({
        position : 'absolute',
        content: '""',
        width : '100%',
        height : ['5px',null,'5px'],
        backgroundColor: 'white',
        zIndex : '9',
        bottom : '2px',
        display : ['block',null,'block']
      }),
      '.dumyborder__left' : mq({
        position : 'absolute',
        content: '""',
        width : ['5px',null,'5px'],
        height : '100%',
        backgroundColor: 'white',
        zIndex : '9',
        left : '2px',
        display : ['block',null,'block']
      }),
      'iframe' : {
        paddingLeft : '0.25rem',
        paddingRight : '0.25rem',
        paddingTop : '0.25rem',
        paddingBottom : '0.25rem'
      }
    }),
    'iframe' : {
      position:'absolute',
      top:0,
      bottom:0,
      left:0,
      width:'100%',
      height:'100%',
      border:0,
      zIndex : 1
    }
  }),
  embed__youtube__container__loading : {
    position:'absolute',
    top: '50%',
    left: '50%',
    transform : 'translate(-50%, -50%)',
    width: '50px',
    height:'50px'
  },
  product__title__line : {
    width:'50%',
    marginLeft:'0px'
  },

  youtube__code__portrait__container : {
    display : 'flex',
    flexDirection : 'row',
    justifyContent: 'center',
    marginTop : '0.5rem',
    marginLeft : '-0.25rem',
    marginRight: '-0.25rem',
    flexWrap : 'wrap'
  }

}