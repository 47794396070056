/** @jsx jsx */
import { jsx } from '@emotion/core'
import { FacebookShareButton, FacebookIcon } from "react-share";
// import { LinkedinShareButton, LinkedinIcon } from "react-share";
// import { TwitterShareButton, TwitterIcon } from "react-share";
//import { WhatsappShareButton, WhatsappIcon } from "react-share";
import { EmailShareButton, EmailIcon } from "react-share";

import { getShareButtonStyle } from './shareButtonStyle';

export function ShareButtons({ shareUrl, pageTitle = null, topLabel = 'SHARE THIS PAGE:' }) {
  const s = getShareButtonStyle();
  const title = pageTitle || window.document.title;
  console.log(title)
  return <div css={s.shareButtons}>
    {/* {topLabel && <div css={s.shareButtonTitle}>{topLabel}</div>} */}
    <span title="Sharing with Facebook">
      <FacebookShareButton css={s.shareButton} url={shareUrl}>
        <FacebookIcon size={50} round={true} />
      </FacebookShareButton>
    </span>
    {/* <span title="Sharing with LinkedIn">
      <LinkedinShareButton css={s.shareButton} url={shareUrl} title={title}>
        <LinkedinIcon size={50} round={true} />
      </LinkedinShareButton>
    </span> */}
    {/* <span title="Sharing with Twitter">
      <TwitterShareButton css={s.shareButton} url={shareUrl} title={title}>
        <TwitterIcon size={50} round={true} />
      </TwitterShareButton>
    </span> */}
    {/* <span title="Sharing with Whatsapp">
      <WhatsappShareButton css={s.shareButton} url={shareUrl} title={title}>
        <WhatsappIcon size={50} round={true} />
      </WhatsappShareButton>
    </span> */}
    <span title="Sharing with Email">
      <EmailShareButton css={s.shareButton} url={shareUrl} subject={title}>
        <EmailIcon size={50} round={true} />
      </EmailShareButton>
    </span>
  </div>
}
