import { fb } from '../../../lib/form';
export function getCategoryListModel(data, defaultCategory) { 
    var options = [{name : 'All', value : ''}];
    if(data.length > 0){
      data.forEach(el => {
        options.push({name : el.categoryName, value : el.categoryId});
      });
    }

    options.push({name : 'Favourites', value : '_favourites_'});

    const categoryList = (defaultCategory && defaultCategory !== '') ? defaultCategory : "";
    
    const model = fb.group({
      categoryList: [categoryList, [], {
        label: 'FILTER BY',
        type: 'select',
        options : options
     }]
    }); 
    return model;
}