const overloading = {
  breakpointNames: ['sm', 'md', 'lg', 'xl', 'xxl'],
  breakpoints: [576, 768, 992, 1200, 1600]
}

const colors = {
  
  magentaColor : '#ee0c76',
  greenColor : '#a3e0d7',
  purpleColor : '#c7b0ee',
  redColor : '#fb5a72',
  yellowColor : '#ffc9a3',
  blueColor : '#8ccde3',

  dkGrayColor : '#252525',
  dkMagentaColor : '#d62377'
}

const variables = {
  familyBase: '"proxima-nova",sans-serif',
  familyHeader: '"proxima-nova",serif',

  primaryColor: colors.magentaColor,
  fontColor: colors.dkGrayColor,
  bgColor: 'white',

  ...colors,
  ...overloading
}

export default variables;