/** @jsx jsx */
import { jsx } from '@emotion/core';
import React, { Fragment, useState } from 'react';
import env from '../../../env';
import { Loading, ErrorPopup, usePost } from '../../../components';
import { useForm } from '../../../form'
import { ProductTile } from './productTile';
import { tileListStyle } from './productTileStyle';
import { getCategoryListModel } from './categoryListModel';

export function ProductItems({ categories, defaultCategory }) {

  const post = usePost();
  React.useEffect(() => {
    post.send(env.apiBase + "/api/product/getProducts", { categories: categories });
    // eslint-disable-next-line
  }, [categories]);

  // React.useEffect(() => {  
  //   if (post.done()) {
  //     setProduct(post.response);
  //   }
  // }, [post])

  if (post.loading()) {
    return <Loading />
  } else if (post.hasErrors()) {
    return <ErrorPopup title="Loading Error" errors={post.errors} />
  }

  const data = post.response;
  console.log('product List', data);

  return data.items && data.items.length > 0 ? 
    <Items items={data.items} categories = {data.categories} defaultCategory = {defaultCategory} /> :
    <div>No products</div>;
}

function Items({ items, categories, defaultCategory }) {
  const s = tileListStyle;
  const model = getCategoryListModel(categories, defaultCategory);
  const categoryListForm = useForm(model, {usePlaceholder : true, onControlChanged: orderItemChanged});

  //const [showProducts, setShowProducts] = useState(items);
  const [showProducts, setShowProducts] = useState(()=> {
    // if(categories.length === 1){
    //   let _items = [];
    //   let _items2 = JSON.parse(JSON.stringify(items));
    //   _items = _items2.filter(item => {
    //     for(let i in item.productCategories){
    //       if(item.productCategories[i].categoryId === categories[0].categoryId){   
    //         item.seqInCategory = item.productCategories[i].seq;
    //         return true;
    //       }
    //     }
    //     return false;
    //   });
    //   sortProudcts(_items);
    //   return _items;
    // }

    if(defaultCategory && defaultCategory !== ''){  
      
      console.log("defaultCategory ---- ");
      
      let _items = [];
      let _items2 = JSON.parse(JSON.stringify(items));
      _items = _items2.filter(item => {
        for(let i in item.productCategories){
          if(item.productCategories[i].categoryId === defaultCategory){   
            item.seqInCategory = item.productCategories[i].seq;
            return true;
          }
        }
        return false;
      });
      sortProudcts(_items);
      return _items;
    }
    return items;
  });

  function orderItemChanged(e, control){
    if(control && control.name === 'categoryList'){
      console.log('controll value', control.value);
      let _items = [];
      if(control.value && control.value !== ''){
        _items = items.filter(item => {
          if(control.value === '_favourites_'){
            if(localStorage.favorites !== ""){
              if(localStorage.favorites.includes(item.productId)){
                return true;
              }
            }
          }
          else{
            for(let i in item.productCategories){
              if(item.productCategories[i].categoryId === control.value){
                item.seqInCategory = item.productCategories[i].seq;
                return true;
              }
            }
          }
          return false;
        });
      }
      else{
        _items = items;
      }
      //sort
      sortProudcts(_items);
      setShowProducts(_items);
    }
  }

  const render = (name) => categoryListForm.renderControl(name, null);
  return (
    <Fragment>
      <div css = {s.categoryList}>
        {render('categoryList')}
      </div>
      <div css={s.tiles}>
        {/* {items.map((item, index) => <ProductTile key={index} product={item} />)} */}
        {
          showProducts.length > 0 ? showProducts.map((item, index) => <ProductTile key={index} product={item} />)
          : <div style = {{margin: 'auto'}}> No product </div>
        }
      </div>
    </Fragment>
  )
}

function sortProudcts(items) {
  
  console.log("Can I have a document ‘Statement of service", items);
  
  items.sort((a, b) => {
    return a.seqInCategory - b.seqInCategory;
  });  
}