import React from 'react';
import { useRouter } from '../../components';
import { SiteLink } from '../../components';
import { BsFillCircleFill } from "react-icons/bs";

export function BottomDock(props) {
  const links = [
    { url: '/products', title: 'PRODUCTS', icon: 'products-green.png', iconHover: 'products-white.png', color : '#a3e0d7' },
    { url: '/whats-new', title: "WHAT'S NEW", icon: 'warranty-green.png', iconHover: 'warranty-white.png', color : '#c7b0ee' },
    { url: '/get-the-look', title: 'GET THE LOOK', icon: 'recipes-green.png', iconHover: 'recipes-white.png', color : '#fb5a72' },
    { url: '/tutorials', title: 'TUTORIALS', icon: 'contact-green.png', iconHover: 'contact-white.png', color : '#ffc9a3' },
    { url: '/where-to-buy', title: 'WHERE TO BUY', icon: 'contact-green.png', iconHover: 'contact-white.png', color : '#8ccde3' }
  ];

  //const path = usePath();
  const { pathname } = useRouter();

  const isCurrent = (link) => {
    return pathname && pathname.toLowerCase() === link.toLowerCase();
  }

  const linkElts = links.map((link, index) =>
    <li key={index} className={`bottomMenu__li ${isCurrent(link.url) ? 'selected' : ''}`}>
      <SiteLink to={link.url} className={`bottomMenu__link bottomMenu__link--${index}`} style={{color:`${link.color}`}}>
        {/* <div className="icon">
          <img className="normal" src={`/assets/navicons/${link.icon}`} alt={link.title} />
          <img className="selected" src={`/assets/navicons/${link.iconHover}`} alt={link.title} />
        </div> */}
        <BsFillCircleFill />
        <div className='title'>{link.title}</div>
      </SiteLink>
    </li>
  );

  // const bottomMenu = useRef(null);
  // useLayoutEffect(() => {
  //   //console.log('useLayoutEffect');
  //   const slider = new BottomDrawer(bottomMenu.current);
  //   setTimeout(() => slider.init(), 1000)
  // }, [])

  return (
    <>
      <nav className="bottomMenu">
        {/* <div className="bottomMenu__bar">
          <div className="bottomMenu__line1"></div>
          <div className="bottomMenu__line2"></div>
        </div> */}
        {/* <div className="bottomMenu__bg"></div> */}
        <ul className="bottomMenu__links">
          {linkElts}
        </ul>
      </nav>
      {/* <div className="bottomMenuPadding"></div> */}
    </>
  );
}
